<template>
  <div class="color_white">
    <a-drawer
      title="添加/编辑"
      placement="right"
      :closable="false"
      :visible="visible" 
      width="40%"
      :after-visible-change="afterVisibleChange" 
      @close="onClose(false)"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
      
      <a-form-model-item label="第三方平台" prop="thirdPlatform">
        <a-select v-model="form.thirdPlatform" style="width: 200px" >
          <a-select-option v-for="item in thirdPlat" :key="item.val" :value="item.val">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="绑定方式" prop="isSku">
        <div v-if="isAdd">
          <a-radio-group v-if="form.thirdPlatform !== 4" v-model="form.isSku">
            <a-radio v-for="item in bindTypeList" :key="item.type" :value="item.val">
              {{ item.type }}
            </a-radio>
          </a-radio-group>
          <span v-else>SKU ID</span>
        </div>
        <span v-else>{{ form.isSku === 1 ? 'SKU ID' : '商品ID' }}</span>
        
      </a-form-model-item>
      <a-form-model-item ref="productId" label="商品ID/SKU ID" prop="productId">
        <a-input
          v-model="form.productId"
          style="width: 200px"
          placeholder="请输入三方商品号"
          @blur="
            () => {
              $refs.productId.onFieldBlur();
            }
          "
        />
      </a-form-model-item>

      <a-form-model-item label="类型" prop="businessType">
        <a-radio-group v-model="form.businessType">
          <a-radio v-for="item in businessTypeList" :key="item.name" :value="item.val">
            {{ item.type }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item ref="time" label="生效时间" prop="time">
        <a-date-picker v-model:value="form.value1" />
      </a-form-model-item>
      
      <a-form-model-item ref="name" label="绑定商品" prop="businessNo">
        <a-button type="primary" @click="modalVisible = true"> {{ isAdd ? '添加商品' : '修改商品'}} </a-button>
        <div v-if="tableList.length > 0">
          <ProductCard :table-list="tableList" />
        </div>
      </a-form-model-item>
      
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit">
          保存
        </a-button>
        <a-button style="margin-left: 10px;" @click="onClose(false)">
          取消
        </a-button>
      </a-form-model-item>
    </a-form-model>
    </a-drawer>
    

    <RadioModal
      v-if="modalVisible"
      :modalVisible="modalVisible"
      :type="0"
      :isShowMember="true"
      :selectedRowKeys="selectedRowKeys"
      @cancel="modalVisible = false"
      @ok="handleOk"
    />
  </div>
</template>
<script>
import { thirdPlat, businessTypeList, businessTypeMap, bindTypeList } from './constants'
import { relationDetailAPI, editRelationAPI, addRelationAPI } from '@/request/api/orderformManage'
import ProductCard from '@/components/product/ProductCard.vue'
import RadioModal from "@/components/courseMember/newRadioModal";
export default {
  components: { RadioModal, ProductCard },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    businessType: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      bindTypeList,
      businessTypeList,
      thirdPlat,
      businessTypeMap,
      labelCol: { span: 6},
      wrapperCol: { span: 14 },
      other: '',
      form: {
        businessNo: '',
        thirdPlatform: 1,
        businessType: 1,
        productId: '',
        isSku: 2
      },
      rules: {
        businessNo: [
          { required: true, message: '请选择商品', trigger: 'change'},
        ],
        productId: [
          { required: true, message: '请输入三方商品号', trigger: 'blur' }
        ],
        thirdPlatform: [{ required: true, message: '请选择三方平台', trigger: 'change' }],
        businessType: [
          { required: true, message: '请选择类型', trigger: 'change' },
        ],
        isSku: [
          { required: true, message: '请选择绑定方式', trigger: 'change' },
        ],
      },
      modalVisible: false,
      tableList: [],
      selectedRowKeys: [],
      isAdd: true
    };
  },
 
  methods: {
    onClose(flag = false) {
      this.$emit('close', flag)
    },
    afterVisibleChange(bool) {
      this.tableList = []
      this.form = this.$options.data().form
      if (!bool) return
      this.isAdd = !+this.id
      if (!this.isAdd) {
        this.getDetail()
      } else {
        this.form.businessType = this.businessType
      }
    },
    async getDetail() {
      const params = {
        id: this.id.toString()
      }
      const { code, data } = await relationDetailAPI(params)
      if (code !== 200) return
      this.form = data.relationdata
      this.tableList.push(data.businessData)

    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.form.thirdPlatform === 4) this.form.isSku = 1
          this.isAdd ? this.addRelation() : this.editRelation()
        }
      });
    },
    async addRelation() {
      const params = {
        thirdPlatForm: this.form.thirdPlatform,
        ...this.form
      }
      const { code } = await addRelationAPI(params) 
      if (code !== 200) return
      this.$message.success('添加成功')
      this.onClose(true)
    },
    async editRelation() {
      const params = {
        thirdPlatForm: this.form.thirdPlatform,
        ...this.form
      }
      const { code } = await editRelationAPI(params)
      if (code !== 200) return
      this.$message.success('修改成功')
      this.onClose(true)
    },
    // 弹窗中确认按钮事件
    handleOk (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
			this.tableList = [selectedRows]
			if (this.tableList[0].memberNo) {
				this.$set(this.form, 'businessNo', this.tableList[0].memberNo)
			} else {
				this.$set(this.form, 'businessNo', selectedRowKeys[0])
			}
      this.modalVisible = false;
    },
  },
};
</script>
